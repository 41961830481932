import request from '@/utils/request'

// 支付管理-列表
export function payList(data) {
    return request({
        url: '/order/pay/list',
        method: 'post',
        data
    })
}
// 支付-获取支付链接以及参数
export function pay(data) {
    return request({
        url: '/bank/zs/pay/url',
        method: 'post',
        data
    })
}
// 支付[分步]-创建支付记录
export function first(data) {
    return request({
        url: '/order/pay/add',
        method: 'post',
        data
    })
}
// 支付[分步]-获取支付链接以及参数
export function second(data) {
    return request({
        url: '/order/pay/url',
        method: 'post',
        data
    })
}
// 支付弹窗信息
export function info(params) {
    return request({
        url: '/order/pay/info',
        method: 'get',
        params
    })
}
// 列表-支付记录
export function list(data) {
    return request({
        url: '/order/cash/list',
        method: 'post',
        data
    })
}
// 按钮-关闭支付
export function closePay(params) {
    return request({
        url: '/order/pay/close/'+params,
        method: 'post',
   
    })
}
// 统计-支付完成度
export function progress(params) {
    return request({
        url: '/order/cash/progress',
        method: 'get',
        params
    })
}

// 验证银行卡是否浙商银行
export function valid(params) {
    return request({
        url: '/order/valid/zs',
        method: 'get',
        params
    })
}

 // 线下支付-收款方数据获取
 export function payee (params) {
    return request({
        url: '/order/payee/list',
        method: 'get',
        params
    })
  }
  
   // 线下支付-新增
  export function add (data) {
    return request({
        url: '/order/pay/offline/add',
        method: 'post',
        data
    })
  }
  
   // 线下支付-支付银行列表
  export function bankList () {
    return request({
        url: '/order/bank/paid/list',
        method: 'get'
    })
  }
  
   // 线下支付-支付银行列表
   export function refound (data) {
    return request({
        url: '/order/refound/add',
        method: 'post',
        data
    })
  }

// 列表-批量选择的待付税列表
export function payTaxList (params) {
    return request({
        url: '/orders/pay/tax/list',
        method: 'get',
        params
    })
}

// 列表-采购商支付的银行卡列表
export function payBankList (params) {
    return request({
        url: '/orders/pay/bank/list',
        method: 'get',
        params
    })
}

// 批量支付税款-获取支付链接以及参数
export function payTaxUrl (params) {
    return request({
        url: '/orders/pay/tax/url',
        method: 'get',
        params
    })
}

// 查询该订单是否可以抵扣
export function advUseStatus (params) {
    return request({
        url: '/trade/pur/adv/use/status',
        method: 'get',
        params
    })
}

// 抵扣
export function advUseDeduct (data) {
    return request({
        url: '/trade/pur/adv/use',
        method: 'post',
        data
    })
}